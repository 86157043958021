<template lang="pug">
  v-container
    - const btnAttrs = { 'color': 'primary', 'class': 'mr-2', ':small': 'true'}
    - const spanAttrs = { 'class': 'caption' }
    v-btn&attributes(btnAttrs)(
      :outlined='$route.name !== "suppliers.create"'
      @click='$router.push({ name: "suppliers.create" })'
    )
      span&attributes(spanAttrs) New Supplier
    v-btn&attributes(btnAttrs)(
      :outlined='$route.name !== "suppliers.table"'
      @click='$router.push({ name: "suppliers.table" })'
    )
      span&attributes(spanAttrs) Suppliers
    v-btn&attributes(btnAttrs)(
      :outlined='$route.name !== "suppliers.categories"'
      @click='$router.push({ name: "suppliers.categories" })'
    )
      span&attributes(spanAttrs) Category
    v-card.mt-2(min-height='80vh')
      router-view
</template>
<script>
export default {
  name: 'Supplier',

  data () {
    return {
    }
  },
}
</script>
